export const newsletters = Object.freeze([
	Object.freeze({
		name: 'Discover Magazine',
		description:
			'Feed your curiosity with the latest science news delivered to your inbox weekly.',
		code: 'DSC'
	}),
	Object.freeze({
		name: 'Astronomy Magazine',
		description:
			'Keep tabs on the latest space missions, recent discoveries and upcoming observing events, delivered weekly.',
		code: 'ASY'
	}),
	Object.freeze({
		name: 'My Science Shop',
		description:
			'Receive information and offers on all the latest products available in our online store.',
		code: 'SCI'
	})
])
